<template>
    <section>
        <v-dialog v-model="dialog" persistent width="auto">
            <v-card width="600" class="overflow-x-hidden ">
                <div class="pa-5" v-if="termsAndCondition">
                    <section class="d-flex flex-row justify-space-between mb-5">
                        <section class="primary-font primary--text fw600 f17">
                            Terms and Conditions
                        </section>
                        <section class="text-right">
                            <v-btn icon @click="() => { reset(), $emit('close') }" dense>
                                <v-icon small>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                        </section>
                    </section>
                    <div class="f15 mt-2 ">
                        <div>
                            I hereby acknowledge that compliance with the <a href="https://sites.google.com/view/csi-collection/home/payment-reminders" target="_blank"><b>PAYMENT REMINDERS</b></a> is a mandatory requirement.
                        </div>
                        <div class="mt-2">
                            I understand that all submissions will undergo validation by the Collection Unit of the Civil Service Institute (CSI) prior to endorsement to the Cash Unit of the Civil Service Commission (CSC). The CSI reserves the right to contact me for clarifications, if necessary.
                        </div>
                        <div class="mt-2">
                            I recognize that non-compliance, including failure to provide valid Proof of Payment (POP), will result in the non-processing or non-recognition of my payment.
                        </div>
                        <div class="mt-2">
                            Furthermore, I acknowledge that all submitted information will be handled in strict compliance with applicable data privacy laws and used solely for verification and processing purposes.
                        </div>
                        <div class="my-3 d-flex flex-row">
                            <v-checkbox v-model="proceed" dense></v-checkbox>
                            <b><i>By proceeding, I confirm that I have read, understood, and accepted these terms and conditions and undertake to comply with the PAYMENT REMINDERS accordingly.</i></b>
                        </div>
                    </div>
                </div>
                <div class="pa-5" v-if="registrationReminder">
                    <section class="d-flex flex-row justify-space-between mb-5">
                        <section class="primary-font primary--text fw600 f17">
                            Registration Reminder
                        </section>
                        <section class="text-right">
                            <v-btn icon @click="() => { reset(), $emit('close') }" dense>
                                <v-icon small>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                        </section>
                    </section>
                    <div class="f15">
                        Participants must agree to the following terms and conditions to complete the registration process.
                        <div class="mt-2 d-flex flex-row">
                            <div class="mr-1">1.</div>
                            Certificates of Completion, including credit hours in Leadership and Management, will be awarded only to participants who attend all sessions and fulfill all training requirements.
                        </div>
                        <div class="mt-1 d-flex flex-row">
                            <div class="mr-1">2.</div>
                            <div>Confirmed and paid participants who fail to attend the course without prior notice will forfeit their registration fee. Participants unable to attend must notify the CSI Training Team by submitting a formal notice signed by the Head of Office or an Authorized Representative. This notice must be submitted at least <b>five (5) working days</b> before the course date.</div>
                        </div>
                        <div class="mt-1 d-flex flex-row">
                            <div class="mr-1">3.</div>
                            Paid registration fees may be applied to future programs instead of requesting a refund. If the new program fee exceeds the original fee, the balance must be settled through the specified payment methods.
                        </div>
                        <div class="mt-1 d-flex flex-row">
                            <div class="mr-1">4.</div>
                            By registering, participants agree to adhere to all course guidelines, policies, and timelines established by the CSI Training Team.
                        </div>
                        <div class="mt-1 d-flex flex-row">
                            <div class="mr-1">5.</div>
                            The CSI Training Team reserves the right to modify program schedules, course content, or delivery methods due to unforeseen circumstances. Any changes will be communicated to participants in advance.
                        </div>
                        <div class="mt-1 d-flex flex-row">
                            <div class="mr-1">6.</div>
                            Any behavior or actions that violate course rules may result in removal from the program. In such cases, refunds may not be provided.
                        </div>
                        <div class="mt-5">
                            By completing the registration process, participants confirm that they have read, understood, and agreed to these <b class="cursor-pointer" @click="registrationReminder = false, termsAndCondition = true"><i class="primary--text text-decoration-underline">Terms and Conditions</i></b>.
                        </div>
                    </div>
                </div>
                
                <v-row dense class="pa-5" v-if="registrationReminder || termsAndCondition" >
                    <v-col>
                        <v-btn text color="secondary" block class="roboto" @click="() => { reset(), $emit('close') }">
                            Cancel
                        </v-btn>
                    </v-col>
                    <v-col>
                        <v-btn color="primary" block :disabled="!(proceed === true)" class="roboto"
                            @click="() => { termsAndCondition = false, howToPayConfirmation = true}">
                            Continue
                        </v-btn>
                    </v-col>
                </v-row>

                <HowToPay 
                    v-if="howToPayConfirmation" 
                    @back="() => { termsAndCondition = true, howToPayConfirmation = false }"
                    @close="() => { reset(), $emit('close') }"
                    @continue="() => { howToPayConfirmation = false, isTheAgencyPayingForYou = true}"
                />

                <IsTheAgencyPayingForYou
                    v-if="isTheAgencyPayingForYou"
                    :course="course"
                    @close="() => { $emit('close'), reset() }"
                    @yes="()=>{ nomination = true, isTheAgencyPayingForYou = false, uploadRequirements = false }"
                    @no="()=>{ nomination = false, isTheAgencyPayingForYou = false, paymentOptions = true  }"
                />

                <PaymentOptions 
                    v-if="paymentOptions"
                    @close="() => { $emit('close'), reset() }"
                    @back="() => { isTheAgencyPayingForYou = true, paymentOptions = false, paymentType = '' }"
                    @setPaymentType="(e) => paymentType = e"
                    @continue="() => { paymentOptions = false, participantOptions = true }"
                />

                <ParticipantType
                    v-if="participantOptions"
                    @close="() => { $emit('close'), reset() }"
                    @back="() => { paymentOptions = true, participantOptions = false, participantType = '' }"
                    @setParticipantType="(e) => participantType = e"
                    @continue="() => { participantOptions = false, uploadRequirements = true }"
                />

                <UploadNomination
                        v-if="nomination"
                        :course="course"
                        @back="() => { uploadRequirements = false, nomination = false, isTheAgencyPayingForYou = true}"
                        @close="() => { $emit('close'), reset() }"
                        @success="() => { success = {
                                successTitle: 'Nomination form and POP has been uploaded',
                                successText: 'Processing may take up to 3 working days.'
                            },  successDialog = true }"
                    />

                <div v-if="uploadRequirements">

                    <UploadCommitmentLetter 
                        v-if="uploadRequirements && paymentType === 'commitment_letter'"
                        @setCommitmentLetter="(e) => commitmentLetter = e"
                    />
                    <UploadPrimeRequirements
                        v-if="uploadRequirements && ['prime_hrm_grantee'].includes(participantType)"
                        @setEndorsementLetter="(e) => endorsementLetter = e"
                        @setPrimeResolution="(e) => primeResolution = e"
                        :error_endorsement_letter="errors.endorsement_letter && errors.endorsement_letter[0]"
                        :error_prime_resolution="errors.prime_resolution && errors.prime_resolution[0]"
                    />

                    <UploadProofOfPayment
                        v-if="uploadRequirements && !nomination && !['linkbiz_portal', 'commitment_letter'].includes(paymentType)"
                        @setProofOfPayment="(e) => proofOfPayment = e"
                        :error="errors.proof_of_payment && errors.proof_of_payment[0]"
                    />

                    <alert 
                        v-if="error && !nomination"
                        :show = "true"
                        :text = "error"
                        :type = "'error'"
                        class="mx-3"
                    />

                    <v-row dense class="pa-3" v-if="!nomination">
                        <v-col>
                            <v-btn text block class="roboto" @click="() => { uploadRequirements = false, participantOptions = true, participantType = '', resetFiles() }">
                                Back
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-btn color="primary" block class="roboto"
                            @click="submitRequirements" :loading="submitLoading">
                                Submit
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
                

            </v-card>
        </v-dialog>
        
        <SuccessDialog
            :dialog="successDialog" 
            :title="success.successTitle" 
            :message="success.successText"
            :icon="true"
        />

    </section>
</template>

<script>
import HowToPay from './HowToPay.vue';
import IsTheAgencyPayingForYou from './IsTheAgencyPayingForYou.vue';
import PaymentOptions from './PaymentOptions.vue';
import UploadNomination from './UploadNomination.vue';
import ParticipantType from './ParticipantType.vue';
import UploadCommitmentLetter from './UploadCommitmentLetter.vue';
import UploadPrimeRequirements from './UploadPrimeRequirements.vue';
import UploadProofOfPayment from './UploadProofOfPayment.vue';
import SuccessDialog from '@/constants/reusable/SuccessDialog.vue';
import { mapActions, mapState } from 'vuex';
import { error } from 'jquery';

export default {
    components: {
        HowToPay,
        IsTheAgencyPayingForYou,
        PaymentOptions,
        ParticipantType,
        UploadNomination,
        UploadCommitmentLetter,
        SuccessDialog,
        UploadPrimeRequirements,
        UploadProofOfPayment
    },
    props: {
        dialog: {
            default: false,
            type: Boolean
        },
        course: {
            default: null,
            type: Object || null
        }
    },
    data: () => ({
        termsAndCondition: false,
        registrationReminder: true,
        howToPayConfirmation: false,
        isTheAgencyPayingForYou: false,
        paymentOptions: false,
        participantOptions: false,
        nomination: false,
        uploadRequirements: false, 
        successDialog: false,
        successCommitmentLetterDialog: true, 
        proceed: false,
        paymentType: '',
        participantType: '',
        success: {
            successTitle: '',
            successText: ''
        },
        commitmentLetter: null,
        primeResolution: null, 
        endorsementLetter: null,
        proofOfPayment: null,
        submitLoading: false,
        error: null,
        proceedToPaymentLoading: false
    }),
    methods: {
        ...mapActions('usr', ['courseCheckoutOwnAccount']),

        reset(){
            this.registrationReminder = true
            this.termsAndCondition = false
            this.howToPayConfirmation = false
            this.isTheAgencyPayingForYou = false
            this.nomination = false
            this.participantOptions = false
            this.paymentOptions = false
            this.uploadRequirements = false
            this.successDialog = false
            this.proceed = false
            this.paymentType = ''
            this.participantType = ''
            this.success = {
                successTitle: '',
                successText: ''
            }
            this.commitmentLetter = null
            this.primeResolution = null
            this.endorsementLetter = null
            this.proofOfPayment = null
            this.error = null
        },

        resetFiles(){
            this.commitmentLetter = null
            this.primeResolution = null
            this.endorsementLetter = null
            this.proofOfPayment = null
            this.error = null
        },

        submitRequirements(){
            let _form = new FormData()

            _form.append('course_id', this.course.id)
            if(this.commitmentLetter) {
                _form.append('commitment_letter', this.commitmentLetter)
            }
            if(this.primeResolution) {
                _form.append('prime_resolution', this.primeResolution)
            }
            if(this.endorsementLetter) {
                _form.append('endorsement_letter', this.endorsementLetter)
            }
            if(this.proofOfPayment) {
                _form.append('proof_of_payment', this.proofOfPayment)
            }
            _form.append('type_of_payment', this.paymentType)
            _form.append('type_of_participant', this.participantType)

            this.submitLoading = true
            this.errors = {}
            this.error = null
            this.courseCheckoutOwnAccount(_form).then(() => {
                this.$emit('close')
                this.reset()
                this.success = {
                        successTitle: 'Attachments has been uploaded',
                        successText: 'Processing may take up to 3 working days.'
                    }

                this.successDialog = true
            }).catch((e) => {
                if(!this.errors.prime_resolution && !this.errors.endorsement_letter && !this.errors.commitment_letter && !this.errors.proof_of_payment) {
                    this.error = e.response.data.message
                }
            }).finally(() => {
                this.submitLoading = false
                console.log(this.errors)
            })
        },
        
        proceedToPayment(){
            this.proceedToPaymentLoading = true

            let _form = new FormData()
            _form.append('course_id', this.course.id)

            this.courseCheckoutLinkBiz(_form).then(res => {
                this.$paymentHandler(res.data)
                this.$emit('close')
            }).catch(err => {
                this.$emit('close'), 
                this.paymentOptionsConfirmation = false,
                this.nominationConfirmation = true
                if(err.response?.status === 409) { 
                    this.alertMutation({
                        show: true,
                        text: err.response.data.message,
                        type: "error"
                    })
                } else {
                    this.alertMutation({
                        show: true,
                        text: 'Something went wrong.',
                        type: "error"
                    })
                }
            }).finally(() => {
                this.proceedToPaymentLoading = false
            })
        }
    },
    computed: {
        ...mapState({
            errors: (state) => state.errors,
        }),
    },
    watch: {
        successDialog(val){
            if(val) {
                setTimeout(() => this.successDialog = false, 5000)
            }
        }
    }
}
</script>