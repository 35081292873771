<template>
    <v-card class="overflow-x-hidden pa-5">
        <section class="d-flex flex-wrap justify-space-between align-center mb-5">
            <section class="primary-font primary--text fw600 f17">
                Type of Participant
            </section>
            <section class="text-right">
                <v-btn icon @click="$emit('close')">
                    <v-icon small>
                        mdi-close
                    </v-icon>
                </v-btn>
            </section>
        </section>

        <FormSelectObject
            :value.sync="type_of_participant"
            :items="type_of_participants"
        />

        <div class="error--text f11" v-if="type_of_participant === ''">Please pick the type of participant from the options.</div>

        <v-row dense class="mt-5">
            <v-col>
                <v-btn block dense text @click="$emit('back')">
                    Back
                </v-btn>
            </v-col>
            <v-col>
                <v-btn primary block dense color="primary" :disabled="type_of_participant === ''" class="roboto"
                    @click="() => { $emit('setParticipantType', type_of_participant), $emit('continue')}">
                    Continue
                </v-btn>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>

export default {
    data: () => ({
        type_of_participant: '',
        type_of_participants: [
            { text: '-', value: '' },
            { text: 'CSC PARTICIPANT', value: 'csc_participant' },
            { text: 'NON CSC PARTICIPANT', value: 'non_csc_participant' },
            { text: 'PRIME HRM GRANTEE', value: 'prime_hrm_grantee' },
        ]
    }),
}
</script>