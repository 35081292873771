const routes = [
  {
    subheader: '',
    lists: [
      {
        title: 'Dashboard',
        icon: 'mdi-view-dashboard',
        route: 'Instructor Dashboard',
        query: { search: '', page: 1, paginate: 10 },
      },
      {
        title: 'My Courses',
        icon: 'mdi-book-open-outline',
        route: 'Instructor My Courses'
      },
      {
        title: 'Assessments',
        icon: 'mdi-note-edit-outline',
        route: 'Instructor Assessment',
        query: { search: '', page: 1, paginate: 10, tab: 0},
      },
      {
        title: 'Rubrics',
        icon: 'mdi-text-box-outline',
        route: 'Instructor Rubric',
        query: { search: '', page: 1, paginate: 10, tab: 0 },
      },
    ]
  },
  {
    subheader: 'USER MANAGEMENT',
    lists: [
      {
        title: 'Enrollments',
        icon: 'mdi-account-clock-outline',
        route: 'Instructor Enrollment',
        query: { search: '', page: 1, paginate: 10, status: '', learning_mode: '', date_from: ``, date_to: `` },
      },
      {
        title: 'Grading',
        icon: 'mdi-notebook-outline',
        route: 'Instructor Grading',
        query: { search: '', page: 1, paginate: 10, status: '', created_from: ``, created_to: `` },
      },
      {
        title: 'Student Outputs',
        icon: 'mdi-book-open-blank-variant',
        route: 'Instructor Student Outputs',
        query: { search: '', page: 1, paginate: 10, classes: '' },
      },
      {
        title: 'Reporting',
        icon: 'mdi-book-open-page-variant-outline',
        route: 'Instructor Reporting'
      },
      {
        title: 'Activity Logs',
        icon: 'mdi-timeline-text',
        route: 'Instructor Activity Logs',
        query: { search: '', page: 1, paginate: 10, log_name: ['user_enrollment', 'user_course_activity', 'user_certificate_gen', 'user_course_complete'], timestamp: Date.now() },
      },
    ]
  },
  {
    subheader: 'COMMUNICATION',
    lists: [
      {
        title: 'Announcements',
        icon: 'mdi-forum-outline',
        route: 'Instructor Announcements',
        query: { search: '', page: 1, paginate: 10, course_id: '', posted_from: `${new Date().toISOString().substr(0, 7)}`, posted_to: `${new Date().toISOString().substr(0, 7)}`},
      },
      {
        
        title: 'Inbox',
        icon: 'mdi-message-processing-outline',
        route: 'Instructor Inbox'
      },
    ]
  },
  {
    subheader: '',
    lists: [
      {
        title: 'Trash',
        icon: 'mdi-trash-can-outline',
        route: 'Instructor Trash',
        query: { search: '', page: 1, paginate: 10 },
      },
    ]
  },
  // {
  //   subheader: 'COURSE BUILDER',
  //   lists: [
  //     {
  //       title: 'Lesson Manager',
  //       icon: 'mdi-book-open',
  //       route: 'Teacher Lesson Manager'
  //     },
  //     {
  //       title: 'Assessment Manager',
  //       icon: 'mdi-numeric',
  //       route: 'Teacher Assessment Manager'
  //     },
  //     {
  //       title: 'Live Sessions',
  //       icon: 'mdi-video-plus',
  //       route: 'Teacher Live Session'
  //     },
  //   ]
  // },
  // {
  //   subheader: 'HELP DESK',
  //   lists: [
  //     {
  //       title: 'Ticketing Support',
  //       icon: 'mdi-ticket-account',
  //       route: 'Teacher Manage Ticket' 
  //     },
  //     {
  //       title: 'FAQS',
  //       icon: 'mdi-help-circle',
  //       route: ''
  //     },
  //   ]
  // },
  // {
  //   subheader: 'ACCOUNT AND SECURITY',
  //   lists: [
  //     {
  //       title: 'Account Settings',
  //       icon: 'mdi-account',
  //       route: 'Teacher Settings' 
  //     },
  //   ]
  // },
]

export default {
  install(Vue) {
    Vue.prototype.$teacherRoutes = routes
  }
}