const navigation_links = [
    {
        text: 'Browse Courses',
        route: '/browse-courses?search=&date=all&page=1&paginate=10&timestamp=1719487047189'
    },
    {
        text: 'Free Trainings',
        route: '/free-trainings'
    },
    {
        text: 'Contact Us',
        route: '/contact-us'
    },
    {
        text: 'FAQs',
        route: '/frequently-asked-questions'
    },

]

const about_links = [
    {
        text: 'The Civil Service Institute',
        route: '/the-civil-service-institute'
    },
    {
        text: 'The Vision is the Challenge',
        route: '/the-vision-is-the-challenge'
    },
    {
        text: 'Our Solutions',
        route: '/our-solutions'
    },
    {
        text: 'Our Partners',
        route: '/our-partners'
    },
    {
        text: 'Our Promise',
        route: '/our-promise'
    },
]

const aldi_links = [
    {
        text: 'ALDIs and ALDPs',
        route: 'https://csc-csi.my.canva.site/2024-csc-aldis-and-aldps'
    },
    {
        text: 'Resolutions',
        route: 'https://drive.google.com/file/d/1TeEh9GLWnu9fE6hGopkDg2nUUvV1DeAi/view?usp=sharing'
    },
]

export { navigation_links, about_links, aldi_links }