export default function() {
  return {
    users: [],
    courses: [],
    classes: [],
    registration_tables: [],
    columns: [],
    filtered_users: [],
    activitylogs: [],
    enrollees: [],
    ticketCount: 0,
    tickets: [],
    logs: [],
  }
}